import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import ReactPlayer from 'react-player/lazy';
import Testimonial from './testimonial';

interface TestimonialMetadata {
	id: number;
	author: string;
	firm: string;
	imgAlt: string;
	text: string;
	imgpath: string;
}

interface TestimonialEntry {
	node: {
		metadata: TestimonialMetadata;
	};
}

const HomeComponent = () => {
	// GraphQL query to fetch data from Cosmic JS
	const testimonialQueryData = useStaticQuery(graphql`
		query {
			allCosmicjsTestimonials(sort: { fields: [metadata___id], order: ASC }) {
				edges {
					node {
						metadata {
							author
							firm
							id
							imgalt
							text
							imgpath
						}
					}
				}
			}
			allCosmicjsHomepagevideo {
				edges {
					node {
						metadata {
							url
						}
					}
				}
			}
			allCosmicjsHomepageshortbio {
				edges {
					node {
						metadata {
							text
						}
					}
				}
			}
		}
	`);

	const shortBioText =
		testimonialQueryData.allCosmicjsHomepageshortbio.edges[0].node.metadata
			.text;

	const videoUrl =
		testimonialQueryData.allCosmicjsHomepagevideo.edges[0].node.metadata.url;

	const testimonialData = testimonialQueryData.allCosmicjsTestimonials.edges.map(
		(edge: TestimonialEntry) => edge.node.metadata
	);

	return (
		<div className="HomeComponent">
			<div className="container">
				<div className="row homeRow1">
					<div className="home-text col-sm-12 text-center pb-4">
						<h1 className="text-center poppins-font home-title">
							Matt <span className="main-color">Murphy</span>
						</h1>
						<p className="mb-4">{shortBioText}</p>
						<Link to="/about">
							<button className="btn btn-about">More about Matt</button>
						</Link>
					</div>
				</div>

				<hr className="separator" />

				<div className="row text-center text-md-none py-3">
					<div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
						<ReactPlayer
							url={videoUrl}
							className="youtube-video-home"
							height="425px"
							width="99%"
						/>
					</div>
				</div>
				<div className="row text-center">
					<div className="col-sm-12 col-md-6">
						<StaticImage
							src="../../static/images/MM2_Home.jpeg"
							alt="Matt with Nick Nurse"
							className="img-fluid home-img mt-3"
						/>
					</div>
					<div className="col-sm-12 col-md-6">
						<StaticImage
							src="../../static/images/MM3_Home.jpg"
							alt="Matt with Joel Embiid"
							className="img-fluid home-img mt-3"
						/>
					</div>
				</div>

				<hr className="separator" />

				{/* Testimonials */}
				<div className="row">
					{testimonialData.map((testimonial: TestimonialMetadata) => (
						<Testimonial
							key={testimonial.id}
							testimonialText={testimonial.text}
							testimonialAuthor={testimonial.author}
							testimonialFirm={testimonial.firm}
							imgPath={testimonial.imgpath}
							imgAlt={testimonial.imgAlt}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default HomeComponent;
